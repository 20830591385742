import axios from 'axios';
import { saveAs } from 'file-saver';
import { stringify } from 'query-string';
import { store } from '../store';
import API_DOMAIN from './apiDomain';

const pageSize = 20;

async function downloadStudentStageWiseReport() {
  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/dashboard/report/stage-wise-excel`;

  const response = await axios.get(url, options);
  const data = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(data, `student-stage-wise-list.xlsx`);
}

async function getInstructorPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-report?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getInstructorPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-performance-report`;
  const response = await axios.post(url, filters, options);
  return response;
}

async function downInstructorPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-performance-report/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

export async function getInstructorPerformanceReviewReport(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}


async function downInstructorPerformanceReviewReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-form-passing-report/download?_filters=${JSON.stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

// newly added
async function downInstructorIncentiveReport(filters) {
  const query = {
    _filters: JSON.stringify(filters),
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let examType;
  if (filters?.testType === 'RTA-YD-TST') {
    examType = 'yard';
  } else if (filters?.testType === 'RD-TST') {
    examType = 'road';
  }
  const url = `${API_DOMAIN}/user/v1/instructors/get-${examType}-incentive/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downInstructorPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-report/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downloadList(request) {
  const { field, order } = request.sort;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
  };

  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  try {
    const response = await axios.get(url, options);

    const data = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(data, `ticket-list${new Date().getTime()}.xlsx`);
  } catch (err) {
    console.log('error in await axios.get', err);
  }
}

async function getInternalExaminerPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/internal-examiner-passing-report?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downInternalExaminerPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/internal-examiner-passing-report/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downloadUpgradedStudentsReport(filters) {
  const query = {
    _filters: JSON.stringify(filters),
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/student/upgraded/report/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getFirstAttemptPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/first-attempt-passing-performance-report`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downFirstAttemptPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/first-attempt-passing-performance-report/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downAdditionalAddOnReport(filters) {
  const query = {
    _filters: JSON.stringify(filters),
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/addon-payment-report/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return response;
}

export {
  downloadStudentStageWiseReport,
  getInstructorPassingReport,
  getInstructorPassingPerformanceReport,
  downInstructorPassingPerformanceReport,
  downInstructorIncentiveReport,
  downInstructorPassingReport,
  downloadList,
  getInternalExaminerPassingReport,
  downInternalExaminerPassingReport,
  downloadUpgradedStudentsReport,
  getFirstAttemptPassingPerformanceReport,
  downFirstAttemptPassingPerformanceReport,
  downAdditionalAddOnReport,
  // getInstructorPerformanceReviewReport,
  downInstructorPerformanceReviewReport,
};

export default {
  downloadStudentStageWiseReport,
  getInstructorPassingReport,
  getInstructorPassingPerformanceReport,
  downInstructorPassingPerformanceReport,
  downInstructorIncentiveReport,
  downInstructorPassingReport,
  downloadList,
  getInternalExaminerPassingReport,
  downInternalExaminerPassingReport,
  downloadUpgradedStudentsReport,
  getFirstAttemptPassingPerformanceReport,
  downFirstAttemptPassingPerformanceReport,
  getInstructorPerformanceReviewReport,
  downAdditionalAddOnReport,
  downInstructorPerformanceReviewReport,
};
